/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDashboardDto } from '../models/AddDashboardDto';
import type { AnalyticsDashboard } from '../models/AnalyticsDashboard';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardsService {

    /**
     * @returns AnalyticsDashboard
     * @throws ApiError
     */
    public static analyticsDashboardControllerGetAll(): CancelablePromise<Array<AnalyticsDashboard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/analytics/dashboards',
        });
    }

    /**
     * @param requestBody
     * @returns AnalyticsDashboard
     * @throws ApiError
     */
    public static analyticsDashboardControllerCreate(
        requestBody: AddDashboardDto,
    ): CancelablePromise<AnalyticsDashboard> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/analytics/dashboards',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static analyticsDashboardControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/analytics/dashboards/{id}',
            path: {
                'id': id,
            },
        });
    }

}
