import { UserProvider } from '@auth0/nextjs-auth0/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type NextPage } from 'next';
import { type AppProps } from 'next/app';
import { useState, type ComponentType, type FC, type PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import * as Sentry from '@sentry/nextjs';
import { API_URL } from '@/services/config';
import { ChakraProvider } from '@/lib/ChakraProvider';
import { OpenAPI } from '@/generated';
import { AppErrorFallback } from '@/components/ErrorBoundary/ErrorBoundary';

import '@/css/base.scss';
import '@/css/focus.scss';
import '@/css/print.scss';
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

// openapi-typescript-codegen sets BASE to the address of the server it used
// during compilation, but we want to make requests based on the environment
// we're running in.
// See api-gen.mjs
OpenAPI.BASE = API_URL;

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  await import('../lib/rest/mocks');
}

export type NextPageWithLayout<T extends object = object> = NextPage<T> & {
  Layout?: ComponentType<PropsWithChildren>;
};

// AppProps's T defaults to `any`, so we have to support it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppPropsWithLayout<T = any> = AppProps<T> & {
  Component: NextPageWithLayout;
};

const onError = (error: Error, info: { componentStack: string }) => {
  Sentry.captureException(error, { extra: info });
};

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => <div>{children}</div>;

const App: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const Layout = Component.Layout ?? DefaultLayout;

  return (
    <UserProvider>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <ErrorBoundary FallbackComponent={AppErrorFallback} onError={onError}>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        </QueryClientProvider>
      </ChakraProvider>
    </UserProvider>
  );
};

export default App;
